const messages = {
  'p.login.error': '登录失败，请尝试重新登录',
  'p.login.subtitle': '上海ABB电机有限公司',
  'p.login.email': '邮箱',
  'p.login.password': '密码',
  'p.login.forgetpwd': '忘记密码？',
  'p.login.signin': '登录',
  'p.login.or': '或',
  'p.login.abbsignin': 'ABB邮箱登录',
  'p.login.cglbjshd': '生成采购零部件送货单',
  'p.login.zzcglbjshd': '生成自制零部件送货单',
  'p.login.error1001': '请填写邮箱',
  'p.login.error1002': '请填写密码',
  'p.login.error1003': '邮箱或密码错误',
  'p.login.error1004': '账户已被锁定',
  'p.login.error1005': '供应商已被锁定',
  'p.errorpage.403': '访问受限',
  'p.errorpage.403.app': '您没有访问当前系统的权限，如需访问请联系管理员',
  'p.errorpage.403.desc': '您没有访问当前页面的权限，如需访问请联系管理员',
  'p.errorpage.404': '找不到页面',
  'p.errorpage.404.desc': '您访问的页面不存在，请检查地址是否正确',
  'p.errorpage.home': '返回首页',
  'p.role.name': '角色',
  'p.role.plural': '角色',
  'p.user.name': '用户',
  'p.user.plural': '用户',
  'p.user.f.email': '邮箱',
  'p.user.f.name': '姓名',
  'p.user.f.roles': '角色',
  'p.user.f.supplier': '供应商',
  'p.user.f.reviewedtime': '复审时间',
  'p.user.s.kw': '姓名，邮箱...',
  'p.user.s.roleph': '所有角色',
  'p.user.selectedtips': '已选择{value}项',
  'p.user.review': '复审',
  'p.dict.key': '键',
  'p.dict.value': '值',
  'p.dict.seq': '顺序',
  'p.dict.parent': '分类',
  'p.dict.allparent': '所有分类',
  'p.dict.noparent': '字典分类',
  'p.emailtpl.key': '编码',
  'p.emailtpl.content': '模板',
  'p.emailtpl.remark': '备注',
  'p.supplier.s.kw': '代码或名称...',
  'p.supplier.code': '供应商代码',
  'p.supplier.name': '供应商名称',
  'p.supplier.synctime': '同步时间',
  'p.supplier.deliveryOffsetDays': '发货提前天数',
  'p.supplier.configuration': '配置',
  'p.supplier.email': '邮箱',
  'p.supplier.userName': '姓名',
  'p.supplier.import': '批量导入配置',
  'p.supplier.export': '导出配置',
  'p.supplier.downloadtpl': '下载配置模板',
  'p.supplier.scm': 'SCM',
  'p.supplier.sqe': 'SQE',
  'p.supplier.pur': 'PUR',
  'p.supplier.poT4Date': '交期开始同步日期',
  'p.claim.isQualityClaim': '是否质量索赔',
  'p.claim.claimMoney': '索赔金额',
  'p.claim.claimBatchNo': '索赔签批编号',
  'p.claim.startDate': '索赔开始时间',
  'p.claim.endDate': '索赔结束时间',
  'p.claim.claimStatus': '索赔状态',
  'p.claim.remark': '备注',
  'p.material.source': '来源',
  'p.material.sourcedeleted': '来源状态',
  'p.material.allsource': '所有来源',
  'p.material.add': '添加供应商物料',
  'p.material.import': '批量导入',
  'p.material.downloadtpl': '下载模板',
  'p.bom.version': '版本号',
  'p.bom.refmaterial': '参考物料号',
  'p.bom.refversion': '参考物料版本',
  'p.bom.subcount': 'BOM子物料数量',
  'p.bom.unit': '单位',
  'p.bom.downloadpdf': '下载pdf',
  'p.inventory.newsurvey': '发起库存调查',
  'p.inventory.submitstatus': '提交状态',
  'p.inventory.submitted': '已提交',
  'p.inventory.unsubmitted': '未提交',
  'p.inventory.createdby': '发起人',
  'p.inventory.batchno': '调查编号',
  'p.inventory.reason': '调查原因',
  'p.inventory.WIP': '在制品数量',
  'p.inventory.stock': '库存数量',
  'p.inventory.createdtime': '发起时间',
  'p.inventory.submittedtime': '提交时间',
  'p.inventory.submittedby': '提交人',
  'p.inventory.newsurveymattip': '换行输入多个物料号',
  'p.inventory.newsurveysucc': '已发起库存调查',
  'p.inventory.newsurveynouser': '部分供应商无SRM账户，请注意查收邮件',
  'p.inventory.newsurveynosupplier': '以下物料无相关供应商，请检查',
  'p.inventory.wipstockrequired': '请填写在制品数量和库存数量',
  'p.inventory.wiprequired': '请填写在制品数量',
  'p.inventory.stockrequired': '请填写库存数量',
  'p.ncr.ncrno': 'NCR号',
  'p.ncr.complaintqty': '异常数量',
  'p.ncr.desc': '异常描述',
  'p.ncr.location': '发现位置',
  'p.ncr.task1': '处理方案',
  'p.ncr.task1status': '处理方案状态',
  'p.ncr.task2': '改善要求',
  'p.ncr.task2status': '改善要求状态',
  'p.ncr.ponumber': '订单号',
  'p.ncr.detailtitle': 'NCR信息',
  'p.ncr.polist': '关联PO',
  'p.ncr.attachments': '附件',
  'p.ncr.createdBy': '开具人',
  'p.ncr.createdOn': 'NCR创建时间',
  'p.ncr.task1_100': '进行中',
  'p.ncr.task1_200': '已完成',
  'p.ncr.task2_100': '待供应商上传报告',
  'p.ncr.task2_101': '已退回',
  'p.ncr.task2_150': '待SQE审批报告',
  'p.ncr.task2_200': '已完成',
  'p.ncr.log.submit': '由{user}上传报告',
  'p.ncr.coding': '分类',
  'p.ncr.task2Deadline': '报告上传期限',
  'p.po.ponumber': 'PO订单号',
  'p.po.poitem': '行号',
  'p.po.quantity': '数量',
  'p.po.t0': '订单需求时间',
  'p.po.t4': '确认交付时间',
  'p.po.t6': '允许发货时间',
  'p.po.deliveryDate': '交付时间',
  'p.po.status100': '待供应商确认交付时间',
  'p.po.status101': '待ABB确认交付时间',
  'p.po.status102': '待供应商确认交付时间',
  'p.po.status150': '待供应商确认修改信息',
  'p.po.status200': '待交付',
  'p.po.status300': '已部分发货',
  'p.po.status350': '已全部发货',
  'p.po.status400': '已完成交收',
  'p.po.status500': '已删除',
  'p.po.deliver': '发货',
  'p.po.deliveredQuantity': '已发货数量',
  'p.po.receivedQuantity': '已收货数量',
  'p.po.deliveryQuantity': '发货数量',
  'p.po.receivingQuantity': '收货数量',
  'p.po.packageNo': '箱号',
  'p.po.remark': '备注',
  'p.po.report': '报告',
  'p.po.actionType': '确认类型',
  'p.po.actionRemark': '确认交付时间',
  'p.po.actionDate': '操作时间',
  'p.po.actionUser': '操作人',
  'p.po.batchUploadReport': '批量上传报告',
  'p.po.tplDelivery': '模板批量发货',
  'p.po.deliveryTpl': '批量发货模板',
  'p.po.tplDeliveryUpload': '批量发货上传',
  'p.po.reportupload': '报告',
  'p.po.detail': '详情',
  'p.po.detailtitle': 'PO信息',
  'p.po.detailSerial': 'PO关联序列号',
  'p.po.reportStatus': '报告状态',
  'p.po.poMovementQuantity': '已开票数量',
  'p.po.soNumber': 'SO订单号',
  'p.po.soItem': 'SO行号',
  'p.po.soProductionMemo': 'SO备注',
  'p.po.materialType': '物料组编码',
  'p.po.delivery': 'PO关联送货单',
  'p.po.reqTrackingNumber': '优先级',
  'p.po.delaycause': '延迟原因',
  'p.po.poRemark': 'PO备注',
  'p.po.log.delivery': '由{user}发货',
  'p.po.log.uploadreport': '由{user}上传报告',
  'p.po.log.T1': '由{user}提交T1',
  'p.po.log.T2': '由{user}提交T2',
  'p.po.log.T3': '由{user}提交T3',
  'p.po.log.T4': '由{user}确认T4',
  'p.po.log.T5': '由{user}确认T5',
  'p.po.log.T6': '由{user}确认T6',
  'p.po.log.ConfirmUpdate': '由{user}确认订单修改信息',
  'p.poncr.status100': '确认交期中',
  'p.poncr.status200': '待交付',
  'p.poncr.status300': '已部分发货',
  'p.poncr.status350': '已全部发货',
  'p.poncr.status400': '已完成交收',
  'p.poncr.ncrtype': 'NCR分类',
  'p.poncr.ncrtype0': '不退库',
  'p.poncr.ncrtype1': '退库',
  'p.poncr.receive': '收货',
  'p.poncr.deliveryDate': 'NCR交期',
  'p.po.actualDeliveryDate': '实际发货时间',
  'p.po.deliveryDateT5': '预计交货时间',
  'p.po.t5': '确认预计交货时间',
  'p.po.fileName': '文件名',
  'p.po.message': '错误原因',
  'p.po.machine': '整机报告',
  'p.period.reportType': '类型',
  'p.period.uploadFrequency': '上传频率',
  'p.period.remark': '备注',
  'p.period.createdOn': '上传时间',
  'p.period.upload': '上传报告',
  'p.period.download': '下载',
  'p.period.report': '报告',
  'p.podelivery.deliveryNo': '送货单号',
  'p.podelivery.storageLocation': '库位',
  'p.podelivery.isMachine': '是否整机',
  'p.podelivery.print': '打印',
  'p.podelivery.print2': '补打发货单',
  'p.podelivery.modalTitle': '送货单',
  'p.podelivery.isScrapped': '是否废弃',
  'p.podelivery.printPackage': '打印箱单',
  'p.pot6.sn': '申请单号',
  'p.pot6.date': '申请发货时间',
  'p.pot6.remark': '备注',
  'p.pot6.applicant': '申请人',
  'p.pot6.approver': '审批人',
  'p.pot6.status-2': '已取消',
  'p.pot6.status-1': '已退回',
  'p.pot6.status0': '待审批',
  'p.pot6.status1': '已通过',
  'p.leavefactoryreport.SONumber': '销售订单号',
  'p.leavefactoryreport.SOItem': '销售订单行项目',
  'p.leavefactoryreport.CalculationData': '设计单号',
  'p.leavefactoryreport.Type': '电机型号',
  'p.leavefactoryreport.SerialNo': '序列号',
  'p.leavefactoryreport.Connection': '电机接法',
  'p.leavefactoryreport.R1': '直流电阻1(Ω)',
  'p.leavefactoryreport.R2': '直流电阻2(Ω)',
  'p.leavefactoryreport.R3': '直流电阻3(Ω)',
  'p.leavefactoryreport.RAverage': '电阻平均(Ω)',
  'p.leavefactoryreport.DZPHD': '电阻不平度(%)',
  'p.leavefactoryreport.A_BC_JYDZ': '相间绝缘A(MΩ)',
  'p.leavefactoryreport.B_C_JYDZ': '相间绝缘B(MΩ)',
  'p.leavefactoryreport.C_JYDZ': '相间绝缘C(MΩ)',
  'p.leavefactoryreport.D_D_JYDZ': '对地绝缘(MΩ)',
  'p.leavefactoryreport.NYDY': '耐压(V)',
  'p.leavefactoryreport.LockVoltage': '堵转(V)',
  'p.leavefactoryreport.Ia_L': '堵转电流A(A)',
  'p.leavefactoryreport.Ib_L': '堵转电流B(A)',
  'p.leavefactoryreport.Ic_L': '堵转电流C(A)',
  'p.leavefactoryreport.IAverage_L': '堵电平均(A)',
  'p.leavefactoryreport.IP_L': '堵电不平度(%)',
  'p.leavefactoryreport.P_L': '堵转(W)',
  'p.leavefactoryreport.NoLoadVoltage': '空载(V)',
  'p.leavefactoryreport.Ia_N': '空载电流A(A)',
  'p.leavefactoryreport.Ib_N': '空载电流B(A)',
  'p.leavefactoryreport.Ic_N': '空载电流C(A)',
  'p.leavefactoryreport.IAverage_N': '空电平均(A)',
  'p.leavefactoryreport.IP_N': '空电不平度(%)',
  'p.leavefactoryreport.P_N': '空载功率(W)',
  'p.leavefactoryreport.Noise': '噪声(DB)',
  'p.leavefactoryreport.Vibration': '振动(mm/s)',
  'p.leavefactoryreport.Result': '结果判定',
  'p.leavefactoryreport.TestDate': '试验日期',
  'p.leavefactoryreport.TestControler': '测试者姓名',
  'p.materialgroup.groupcode': '物料组编码',
  'p.materialgroup.groupname': '物料组名称',
  'p.materialgroup.groupdes': '物料组描述',
  'p.materialgroup.ismachine': '是否整机',
  'p.mold.po': '350订单',
  'p.mold.ponumber': '350订单号',
  'p.mold.blankno': '零件号',
  'p.mold.blanknotips': '模具号码或者代表性物料号码',
  'p.mold.blankdesc': '毛坯描述',
  'p.mold.owner': 'SCM',
  'p.mold.category': '大类',
  'p.mold.tech': '模具使用工艺',
  'p.mold.platform': '产品平台',
  'p.mold.baseno': '机座号',
  'p.mold.name': '模具名称',
  'p.mold.ownership': '模具所有权',
  'p.mold.version': '版本号',
  'p.mold.user': '模具使用商',
  'p.mold.proddate': '模具生产日期',
  'p.mold.life': '模具寿命',
  'p.mold.quantity': '模具数量',
  'p.mold.cavity': '一模几穴',
  'p.mold.manufacturer': '模具生产商',
  'p.mold.size': '模具型板/产品尺寸',
  'p.mold.stock': '模具已生产数量',
  'p.mold.other': '其他信息',
  'p.mold.amount': '模具成本（不含税）',
  'p.mold.drawing': '图纸',
  'p.mold.status.-1': '已报废',
  'p.mold.status.0': '待ABB完善信息',
  'p.mold.status.1': '待供应商确认',
  'p.mold.status.2': '待审批',
  'p.mold.status.3': '已审批',
  'p.mold.new': '新建行',
  'p.mold.fillorder': '完善信息',
  'p.mold.startflow': '发起流程',
  'p.mold.confirmorder': '确认订单',
  'p.mold.updatemold': '修模',
  'p.mold.scrap': '报废',
  'p.moldunfilled.edittitle': '完善模具信息',
  'p.moldorder.edittitle': '确认模具订单',
  'p.mold.log.fillorder': '由{user}完善信息',
  'p.mold.log.startflow': '由{user}发起流程',
  'p.mold.log.editmold': '由{user}修模',
  'p.mold.log.starteditflow': '由{user}发起修模流程',
  'p.mold.log.scrap': '由{user}报废',

  'p.login.concode': '托盘号',
  'p.login.macode': '物料代码',
  'p.login.madesr': '物料描述',
  'p.login.remark': '备注',
  'p.login.soitem': 'SO/ITEM',
  'p.login.deliverynum': '发货数量',
  'p.login.ordernno': '订单号',
  'p.login.lineno': '行号',
  'p.login.barcode': '条码',
  'p.login.suppliercode': '供应商名称',
  'p.login.deliverytime': '送货日期',
  'p.login.deliveryno': '送货单号',

  'p.login.productorderno': '生产订单号',
  'p.login.mb': '模板',

  'p.file.materialgroup': '物料组',
  'p.file.filename': '文件名称',
  'p.file.filedescrip': '文件描述',
  'p.file.filetype': '文档类型',
  'p.file.fileversion': '版本号',

  'p.file.notfi': 'NCR号',
  'p.file.deliveryReport': '交付列表',

  'p.plant': '工厂',
  'p.Vender': '供应商代码',
  'p.VenderName': '供应商',
  'p.MaterialCode': '物料编码',
  'p.MaterialDescription': '物料描述',
  'p.BaseUnit': '计量单位',
  'p.MDFcode': 'MDF code',
  'p.Num': '数量',
  'p.PODate': 'PO日期',
  'p.Deliverydate': '出库日期',
  'p.PG': 'PG',

  'p.notfidesc': 'NCR描述',
  'p.textcode': 'Text-Code',
  'p.Createdon': '创建日期',
  'p.quality': '数量',
  'p.defectloc': 'Defect Loc',
  'p.PPMexemption': 'PPM exemption',
  'p.Comments2': 'Comments2',
  'p.Comments3': 'Comments3',
  'p.quality': '数量',
  'p.remark': '备注',

  'p.NCRReport': '质量绩效',
  'p.claimImport': '索赔导入',

};
export default messages;
