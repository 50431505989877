import { lazy } from 'react';
//import SideMenu from '../components/SideMenu';
import { LayoutNoBodyWrapper } from '../components/LayoutDefault';

// auth: 验证权限
// menu: 菜单，如果为空与auth一致
// anonymous: 默认false
// layout: 默认LayoutDefault
// exact: 是否精确匹配
// routes: 子路由
const routes = [
  {
    auth: 'home',
    path: '/home',
    component: lazy(() => import('../features/home/Home')),
    layout: LayoutNoBodyWrapper,
  },
  //#region tech
  {
    auth: 'drawing',
    path: '/techinfo/drawing',
    component: lazy(() => import('../features/tech/Drawing')),
  },
  {
    auth: 'bom',
    path: '/techinfo/bom',
    component: lazy(() => import('../features/tech/BOMList')),
  },
  {
    auth: 'filequery',
    path: '/techinfo/filequery',
    component: lazy(() => import('../features/tech/FileQueryTable')),
  },
  //#endregion
  {
    auth: 'polist',
    path: '/po/list',
    component: lazy(() => import('../features/po/POList')),
  },
  {
    auth: 'polist',
    path: '/po/list/:id',
    component: lazy(() => import('../features/po/PODetail')),
  },
  {
    auth: 'poncr',
    path: '/po/ncr',
    component: lazy(() => import('../features/po/PONCR')),
  },
  {
    auth: 'podelivery',
    path: '/po/delivery',
    component: lazy(() => import('../features/po/PODelivery')),
  },
  {
    auth: 'pot6',
    path: '/po/t6request',
    component: lazy(() => import('../features/po/T6RequestList')),
  },
  {
    auth: 'pot6',
    path: '/po/t6request/:sn',
    component: lazy(() => import('../features/po/T6RequestDetail')),
  },
  {
    auth: 'inventorysurvey',
    path: '/inventorysurvey',
    component: lazy(() => import('../features/inventory/InventoryList')),
  },
  {
    auth: 'moldunfilled',
    path: '/mold/unfilled',
    component: lazy(() => import('../features/mold/UnfilledList')),
  },
  {
    auth: 'mold:fillorder',
    menu: 'moldunfilled',
    path: '/mold/unfilled/:id',
    component: lazy(() => import('../features/mold/UnfilledFormPage')),
  },
  {
    auth: 'moldorder',
    path: '/mold/order',
    component: lazy(() => import('../features/mold/OrderList')),
  },
  {
    auth: 'mold',
    menu: 'moldorder',
    path: '/mold/order/:id',
    component: lazy(() => import('../features/mold/OrderDetail')),
  },
  {
    auth: 'mold:confirmorder',
    menu: 'moldorder',
    path: '/mold/order/:id/confirm',
    component: lazy(() => import('../features/mold/OrderConfirmPage')),
  },
  {
    auth: 'mold:update',
    menu: 'moldorder',
    path: '/mold/order/:id/edit',
    component: lazy(() => import('../features/mold/OrderFormPage')),
  },
  {
    auth: 'ncr',
    path: '/quality/ncr',
    component: lazy(() => import('../features/quality/ncr/NCRList')),
  },
  {
    auth: 'ncr',
    path: '/quality/ncr/:ncrnumber',
    component: lazy(() => import('../features/quality/ncr/NCRDetail')),
  },
  {
    auth: 'claim',
    path: '/quality/claim',
    component: lazy(() => import('../features/quality/claim/QualityClaimList')),
  },
  {
    auth: 'periodreport',
    path: '/quality/period',
    component: lazy(() =>
      import('../features/quality/period/PeriodReportList')
    ),
  },
  {
    auth: 'leavefactoryreport',
    path: '/quality/leavefactoryreport',
    component: lazy(() =>
      import('../features/quality/leavefactoryreport/LeaveFactoryReportList')
    ),
  },
  //#region md
  {
    auth: 'supplierbasic',
    path: '/masterdata/supplierbasic',
    component: lazy(() => import('../features/md/supplier/SupplierBasicList')),
  },
  {
    auth: 'suppliermaterial',
    path: '/masterdata/suppliermaterial',
    component: lazy(() =>
      import('../features/md/supplier/SupplierMaterialList')
    ),
  },
  {
    auth: 'dictionary',
    path: '/masterdata/dictionary',
    component: lazy(() => import('../features/md/dictionary/DictionaryPage')),
  },
  {
    auth: 'materialgroup',
    path: '/masterdata/materialgroup',
    component: lazy(() =>
      import('../features/md/materialgroup/MaterialGroupList')
    ),
  },
  {
    auth: 'deliveryreport',
    path: '/quality/deliveryreport',
    component: lazy(() =>
      import('../features/quality/deliveryquantity/ReportList')
    ),
  },
  {
    auth: 'dashboardreport',
    path: '/quality/dashboardreport',
    component: lazy(() =>
      import('../features/quality/qualitydashboard/DashboardList')
    ),
  },
  {
    auth: 'dashboard',
    path: '/quality/dashboard',
    component: lazy(() =>
      import('../features/quality/Chart/Chart')
    ),
  },
  //#endregion
  //#region infra
  {
    auth: 'abbuser',
    path: '/user/abb',
    component: lazy(() => import('../features/setting/user/UserList')),
  },
  {
    auth: 'supplieruser',
    path: '/user/supplier',
    component: lazy(() => import('../features/setting/user/SupplierUserList')),
  },
  {
    auth: 'menu',
    path: '/setting/menu',
    component: lazy(() => import('../features/setting/menu/MenuList')),
  },
  {
    auth: 'role',
    path: '/setting/role',
    component: lazy(() => import('../features/setting/role/RoleList')),
  },
  {
    auth: 'emailtemplate',
    path: '/setting/emailtemplate',
    component: lazy(() =>
      import('../features/setting/emailtpl/EmailTemplateList')
    ),
  },
  //#endregion
  {
    path: '/signin',
    component: lazy(() => import('../features/auth/SignIn')),
    anonymous: true,
    layout: false,
  },
  {
    path: '/resetpassword',
    component: lazy(() => import('../features/auth/ResetPassword')),
    anonymous: true,
    layout: false,
  },
  {
    path: '/DeliveryOrder/Purchase',
    component: lazy(() => import('../features/auth/DeliveryOrder/Purchase')),
    anonymous: true,
    layout: false,
  },
  {
    path: '/DeliveryOrder/SelfControl',
    component: lazy(() => import('../features/auth/DeliveryOrder/SelfControl')),
    anonymous: true,
    layout: false,
  },
  {
    path: '/changepassword',
    component: lazy(() => import('../features/auth/ChangePassword')),
  },
  {
    path: '/signin-internal-use-only',
    component: lazy(() => import('../features/auth/SignInTest')),
    anonymous: true,
    layout: false,
  },
];

function standardize(items) {
  for (let item of items) {
    // menu
    if (item.auth && !item.menu) item.menu = item.auth;
    // anonymous
    item.anonymous = item.anonymous === true ? true : false;
    // exact
    if (item.exact === undefined)
      item.exact = !(item.routes && item.routes.length > 0);
    if (item.routes) standardize(item.routes);
  }
}

standardize(routes);

export default routes;
