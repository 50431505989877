import React from 'react';
import { Menu, Divider, Dropdown } from 'antd';
import { UserOutlined, ExportOutlined, DownOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectIsEmployee,
  selectMenus,
  selectName,
  signOut,
} from '../features/auth/authSlice';
import { Link, useHistory } from 'react-router-dom';
import './LayoutDefault.less';
import { FormattedMessage, useIntl } from 'react-intl';
//import LanguageSetting from './LanguageSetting';
import { logout } from '@/api/_auth';

function LayoutDefault({ children, menu, hideBodyWrapper }) {
  const isEmployee = useSelector(selectIsEmployee);

  return (
    <div className="layout-default">
      <div className="app-header-container">
        <div className="app-header-wrapper">
          <div className="abb-bar">
            <FormattedMessage id="layout.appname" />
          </div>
          <div className="app-header-bar">
            <Navbar currentMenu={menu} />
            {isEmployee ? <Toolbar /> : <SupplierToolbar />}
          </div>
        </div>
      </div>
      <div className="app-body-container">
        <div
          className={`app-body-wrapper ${
            hideBodyWrapper ? 'hide-wrapper' : ''
          }`}
        >
          {children}
        </div>
      </div>
      <div className="app-footer-container">
        <div className="app-footer-wrapper">
          &copy; {process.env.REACT_APP_COPYRIGHT}
        </div>
      </div>
    </div>
  );
}

export default LayoutDefault;

export const LayoutNoBodyWrapper = ({ children, menu }) => (
  <LayoutDefault menu={menu} hideBodyWrapper={true}>
    {children}
  </LayoutDefault>
);

// child component
function Navbar({ currentMenu }) {
  const intl = useIntl();
  // selector
  const menus = useSelector(selectMenus);
  function getNavItems(items) {
    return items.map(x => {
      if (x.children && x.children.length > 0) {
        return {
          key: x.menuCode,
          label: 
          //x.menuName, //菜单名称 根据后端值
          intl.formatMessage({
            id: `menu.${x.menuCode}`,
            defaultMessage: x.menuName,
          }),
          
          children: getNavItems(x.children),
        };
      } else {
        return {
          label: (
            <Link to={x.url}>
             {/* { x.menuName} */}
              <FormattedMessage
                id={`menu.${x.menuCode}`}
                defaultMessage={x.menuName}
              /> 
            </Link>
          ),
          key: x.menuCode,
        };
      }
    });
  }
  const items = getNavItems(menus);
  return <Menu selectedKeys={[currentMenu]} mode="horizontal" items={items} />;
}

const Toolbar = () => {
  const dispatch = useDispatch();
  const name = useSelector(selectName);
  const handleLogout = () => {
    logout().then(() => {
      dispatch(signOut());
      window.location.href = window.ENV_CFG.signout;
   })
    // window.location.href = window.ENV_CFG.signout;
  };

  return (
    <div className="app-header-bar-controls">
      {/* <div className="control-item">
        <LanguageSetting className="lang" />
      </div> */}
      {name && name.length > 0 && (
        <React.Fragment>
          <Divider type="vertical" />
          <div className="control-item">
            <UserOutlined /> {name}
          </div>
          <Divider type="vertical" />
          <div className="control-item" onClick={handleLogout}>
            <ExportOutlined />
            <FormattedMessage id="layout.signout" />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const SupplierToolbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const name = useSelector(selectName);
  const handleClick = ({ key }) => {
    switch (key) {
      case 'changepwd':
        history.push('/changepassword');
        break;
      case 'signout':
        dispatch(signOut());
        break;
      default:
        break;
    }
  };
  const menu = (
    <Menu onClick={handleClick}>
      <Menu.Item key="changepwd">修改密码</Menu.Item>
      <Menu.Item key="signout">退出</Menu.Item>
    </Menu>
  );
  return (
    <div className="app-header-bar-controls">
      {/* <div className="control-item">
        <LanguageSetting className="lang" />
      </div> */}
      {name && name.length > 0 && (
        <React.Fragment>
          <Divider type="vertical" />
          <Dropdown overlay={menu}>
            <div className="control-item">
              <UserOutlined /> {name}
              <DownOutlined style={{ marginLeft: 8, fontSize: 12 }} />
            </div>
          </Dropdown>
        </React.Fragment>
      )}
    </div>
  );
};
