import axios from 'axios';
import { debugEnable } from '@/utils/utils';
import { getLocale } from '@/locales';
import { getRefreshToken, refreshToken } from './_auth';
import qs from 'qs';

const instance = axios.create({
  baseURL: window.ENV_CFG.api_root,
  timeout: 60000,
  headers: { 'Accept-Language': getLocale() },
});


// Add a request interceptor
instance.interceptors.request.use(
  config => {
    if (config.method === 'get') {
      if (parseInt(axios.VERSION.substring(0, 1)) > 0) {
        config.paramsSerializer = {
          indexes: null
        }
      } else {
        config.paramsSerializer = function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        };
      }
    }
    return config;
  },
  error => {
    // Do something with request error
    if (debugEnable) console.log('[http]request error:', error);
    return Promise.reject({ status: 400 });
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (debugEnable)
      console.log('[http]response error:', error, error.response);

    if (error.response) {
      if (error.response.status === 401 && !error.response.config.isretry) {
        return refreshToken(getRefreshToken())
          .then(res => {
            if (res.access_token) {
              error.response.config.isretry = true;
              error.response.config.headers[
                'Authorization'
              ] = `${res.token_type} ${res.access_token}`;
              return instance.request(error.response.config);
            } else {
              return Promise.reject({
                status: 401,
                data: 'refresh_token_error',
              });
            }
          })
          .catch(e => {
            return Promise.reject({
              status: 401,
              data: 'refresh_token_error',
            });
          });
      } else {
        const res = JSON.parse(JSON.stringify(error.response));
        return Promise.reject(res);
      }
    } else {
      return Promise.reject({ status: 0 });
    }
  }
);

const http = {
  instance: instance,
  get(url, config) {
    return instance.get(url, config);
  },
  post(url, data, config) {
    return instance.post(url, data, config);
  },
  delete(url, config) {
    return instance.delete(url, config);
  },
  setAuthorization(token) {
    if (token?.token_type && token?.access_token) {
      instance.defaults.headers.common[
        'Authorization'
      ] = `${token.token_type} ${token.access_token}`;
    } else {
      instance.defaults.headers.common['Authorization'] = token;
    }
  },
  getAuthorization() {
    return instance.defaults.headers.common['Authorization'];
  },
};

export default http;

// export const download = (url, query, data) => {
//   if (query) {
//     const searchParams = new URLSearchParams();
//     Object.keys(query).forEach(key => {
//       if (query[key] === null || query[key] === undefined) {
//         searchParams.append(key, '');
//       } else {
//         searchParams.append(key, query[key]);
//       }
//     });
//     url = url.includes('?')
//       ? `${url}&${searchParams.toString()}`
//       : `${url}?${searchParams.toString()}`;
//   }
//   const form = document.createElement('form');
//   form.target = '_blank';
//   form.action = `${window.ENV_CFG.api_root}/download`;
//   form.method = 'post';
//   form.appendChild(getInput('url', url));
//   form.appendChild(getInput('token', http.getAuthorization()));
//   form.appendChild(getInput('data', data ? JSON.stringify(data) : ''));
//   document.body.appendChild(form);
//   form.submit();
//   document.body.removeChild(form);
// };
// export const download = ({ url, documentName,authKey }) => {
// //  debugger;
// //   const form = document.createElement('form');
// //   form.target = '_blank';
// //   form.action = 
// //      `${window.ENV_CFG.tdms_api_root}${url}`;
// //   form.method = 'post';
// //   form.enctype = 'application/x-www-form-urlencoded';
// //   form.appendChild(getInput('documentName', documentName));
// //    form.appendChild(getInput('authKey', authKey));
// //   document.body.appendChild(form);
// //   form.submit();
// //   document.body.removeChild(form);

//     const formData = new FormData();  
//     formData.append('documentName', documentName);  
//     formData.append('authKey', authKey); 

//     let fm = {};
//     fm.documentName = documentName;
//     fm.authKey=authKey;
//     const Reurl = `${window.ENV_CFG.tdms_api_root}${url}`;
//     try {  
//       const response =  fetch(Reurl, {  
//         method: 'POST', 
//         headers: {  
//           'Content-Type': 'application/json',  
//         }, 
//         body:JSON.stringify(fm) ,  
//       });  

//       if (!response.ok) {  
//         throw new Error(`HTTP error! status: ${response.status}`);  
//       }  

//       // 获取文件内容  
//       const blob =  response.blob();  
//       const link = document.createElement('a');  
//       link.href = window.URL.createObjectURL(blob);  
//       link.download = documentName || 'downloadedFile'; // 设置下载文件名  
//       document.body.appendChild(link);  
//       link.click();  

//       // 清理  
//       document.body.removeChild(link);  
//       window.URL.revokeObjectURL(link.href);  

//     } catch (error) {  
//       console.error('下载失败:', error);  
//     }  
// };


export const downloadAuthFile = ({ url, query, data, previewName, method = 'GET', documentName, authKey }) => {
  if (query) {
    const searchParams = new URLSearchParams();
    Object.keys(query).forEach(key => {
      if (query[key] === null || query[key] === undefined) {
        searchParams.append(key, '');
      } else if (Array.isArray(query[key])) {
        query[key].forEach(x => {
          searchParams.append(key, x);
        });
      } else {
        searchParams.append(key, query[key]);
      }
    });
    url = url.includes('?')
      ? `${url}&${searchParams.toString()}`
      : `${url}?${searchParams.toString()}`;
  }
  const form = document.createElement('form');
  form.target = '_blank';
  form.action = previewName
    ? `${window.ENV_CFG.api_root}/download/preview/${encodeURIComponent(
      previewName
    )}`
    : `${window.ENV_CFG.api_root}/download`;
  form.method = 'post';
  form.appendChild(getInput('url', url));
  form.appendChild(getInput('token', http.getAuthorization()));
  form.appendChild(getInput('data', data));
  form.appendChild(getInput('method', method));
  if (documentName != undefined) {
    form.appendChild(getInput('documentName', documentName));
  }
  if (authKey != undefined) {
    form.appendChild(getInput('authKey', authKey));
  }
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};
const getInput = (name, value) => {
  const input = document.createElement('input');
  input.type = 'hidden';
  input.name = name;
  input.value = value;
  return input;
};

export const uploadRequest = options => {
  http.post(options.action, options.data, {
    headers: { 'content-type': 'multipart/form-data' },
  });
};
