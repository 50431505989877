import error from './zh-CN/error';
import menu from './zh-CN/menu';
import pages from './zh-CN/pages';

const zhCN = {
  material_no: '物料号',
  material_desc: '物料描述',
  maktxl: '物料描述长文本',
  supplier: '供应商',
  supplier_code: '供应商代码',
  supplier_name: '供应商名称',
  all: '所有',
  createdOn: '创建时间',
  itemsselected: '已选择{count}项',
  remark: '备注',
  pur: '采购员',
  'common.loading': '加载中...',
  'common.search': '查询',
  'common.export': '导出',
  'common.upload': '上传',
  'common.download': '下载',
  'common.batchdownload': '批量下载',
  'common.batchdownloadTemp': '批量下载模板',
  'common.clear': '清除',
  'common.back': '返回',
  'common.add': '添加',
  'common.edit': '编辑',
  'common.delete': '删除',
  'common.batchdelete': '批量删除',
  'common.save': '保存',
  'common.cancel': '取消',
  'common.fill': '填写',
  'common.submit': '提交',
  'common.confirm': '确认',
  'common.reset': '重置',
  'common.Close': '关闭',
  'common.OnGoing': '处理中',
  'common.Cancel': '取消',
  'common.actcfm': '确认要{action}{value}吗？',
  'common.delcfm': '确认要删除{value}吗？',
  'common.thisitem': '这条记录',
  'common.selecteditems': '选中项',
  'common.selectedrequired': '请至少选择一项',
  'common.deleting': '正在删除...',
  'common.submitting': '正在提交...',
  'common.saving': '正在保存...',
  'common.lock': '锁定',
  'common.unlock': '解锁',
  'common.view': '查看',
  'common.approval': '审批',
  'common.approve': '通过',
  'common.reject': '退回',
  'common.update': '修改',
  'common.yes': '是',
  'common.no': '否',
  'common.unknown': '未知',
  'common.nocontent': '无内容',
  'common.loadmore': '加载更多',
  'common.success': '成功',
  'common.example': '例如：{value}',
  'common.status': '状态',
  'common.allstatus': '所有状态',
  'common.normal': '正常',
  'common.locked': '已锁定',
  'common.deleted': '已删除',
  'common.expand': '展开',
  'common.collapse': '收起',
  'layout.appname': '供应商管理系统',
  'layout.lang': '多语言',
  'layout.signout': '退出',
  'log.add': '由{user}新增',
  'log.edit': '由{user}修改',
  'log.update': '由{user}修改',
  'log.enable': '由{user}启用',
  'log.disable': '由{user}禁用',
  'log.submit': '由{user}提交',
  'log.approve': '由{user}通过',
  'log.reject': '由{user}退回',
  'log.cancel': '由{user}取消',
  'log.confirm': '由{user}确认',
  'placeholder.select': '请选择...',
  'placeholder.autocomplete': '请填写或选择...',
  ...error,
  ...menu,
  ...pages,
};
export default zhCN;
