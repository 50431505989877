import axios from 'axios';
import http from './_http';
import docCookies from '@/utils/cookie';
import { md5 } from '@/utils/md5';
import JSEncrypt from 'jsencrypt';

export function logout() {
  return http.get('/api/Account/logout');
 }


const client_id = 'web';
const cookieKey = process.env.REACT_APP_TOKEN;

export function removeToken() {
  http.setAuthorization('');
  if(typeof(Storage)!=="undefined")
    {
      localStorage.removeItem(cookieKey);
    } else {
        docCookies.removeItem(cookieKey);
    }
}

export function getRefreshToken() {
  if(typeof(Storage)!=="undefined")
    {
      return localStorage.getItem(cookieKey);
    } else {
      return docCookies.getItem(cookieKey);
    }
}
function setRefreshToken(token) {
  if(typeof(Storage)!=="undefined")
    {
       localStorage.setItem(cookieKey,token);
    } else {
      docCookies.setItem(cookieKey, token);
    }
}

export function authorizeCode(code, redirect_uri) {
  const data = {
    grant_type: 'authorization_code',
    code,
    redirect_uri,
  };
  return getToken(data);
}
export function refreshToken(refresh_token) {
  const data = {
    grant_type: 'refresh_token',
    refresh_token,
  };
  return getToken(data);
}

function getToken(data) {
  data.client_id = client_id;
  data.timestamp = parseInt(new Date().getTime() / 1000);
  data.client_secret = getSecret(data.timestamp);
  return axios
    .post(window.ENV_CFG.token, data)
    .then(res => {
      if (res.data?.access_token) {
        http.setAuthorization(res.data);
        setRefreshToken(res.data.refresh_token);
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res);
      }
    })
    .catch(e => {
      return Promise.reject(e);
    });
}

function getSecret(timestamp) {
  const hash = md5('irXvWZ5ff3qtElnz' + timestamp);
  return hash.slice(8, 16 + (timestamp % 16));
}

export function getRSAKey() {
  return axios
    .get(window.ENV_CFG.api_root + '/Auth/GetRSAKey', {
      params: { client_id },
    })
    .then(res => {
      if (res && res.status === 200 && res.data) {
        return Promise.resolve(res);
      } else {
        return Promise.resolve({ status: 500 });
      }
    })
    .catch(e => Promise.reject(e));
}

export function validatePassword(data) {
  const { password, ...formdata } = data;
  return getRSAKey()
    .then(res => {
      const pwdts = {
        password,
        timestamp: Math.round(new Date().getTime() / 1000),
      };
      const encryptor = new JSEncrypt();
      encryptor.setPublicKey(res.data);
      formdata.client_id = client_id;
      formdata.password = encryptor.encrypt(JSON.stringify(pwdts));
      return axios.post(window.ENV_CFG.valpwd, formdata);
    })
    .catch(e => Promise.reject(e));
}
